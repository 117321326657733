import React from 'react';
import { Page, PageContent } from 'grommet';


export function PhotosPage(): JSX.Element {
    return (
        <Page>
            <PageContent >
                Test2
            </PageContent>
        </Page>
    );
}