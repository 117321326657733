import React, { useMemo, useState } from 'react';
import { Anchor, Box, Button, Grommet, Header, Heading, Nav, Page, PageContent, ThemeType, Image, GrommetProps, PageHeader } from 'grommet';
import { theme } from "./Theme";
import { Home, Code, Map, Sun } from "grommet-icons";
import { PhotosPage } from './pages/Photos';
import { ThemeModeToggleButton } from './components/ThemeModeToggleButton';
import UnderConstructionImage from "./assets/under_construction.webp";

type ThemeModeType = GrommetProps["themeMode"];

function App(): JSX.Element {

  const pages = useMemo(() => [
    <TestPage />,
    <PhotosPage />
  ], []);

  const [currentPage, setCurrentPage] = useState(0);
  const [themeMode, setThemeMode] = useState<ThemeModeType>("dark");

  /*
  return (
    <Grommet theme={theme} themeMode={themeMode}>
      <Page kind='narrow' style={{height: '100vh'}}>
        <PageHeader
          title="Hello"
          pad="medium"
          actions={<Nav direction="row" responsive>
            <Button icon={<Home />} onClick={() => setCurrentPage(0)} />
            <Button icon={<Image />} onClick={() => setCurrentPage(1)}/>
            <Button icon={<Code />}  onClick={() => setThemeMode((prev) => toggleThemeMode(prev))}/>
            <ThemeModeToggleButton themeMode={themeMode} onClick={() => setThemeMode((prev) => toggleThemeMode(prev))} />
            <Button icon={<Map />}  />
          </Nav>}
        />
        {pages[currentPage]}
      </Page>
    </Grommet>
  );
  */

  return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}><Image src={UnderConstructionImage} /></div>;
}

function toggleThemeMode(currentThemeMode: ThemeModeType): ThemeModeType {
  return currentThemeMode === "dark" ? "light" : "dark";
}

function TestPage(): JSX.Element {
  return <Page>
    <PageContent >
      Test
    </PageContent>
  </Page>;
}

export default App;
